import { MetaSwiper } from "./meta-settings.js";
import { CountUp } from "./plugins/countUp.min.js";
// import { Swiper } from "./plugins/swiper-bundle.esm.browser.min.js";
import { Swiper } from "https://cdnjs.cloudflare.com/ajax/libs/Swiper/8.4.4/swiper-bundle.esm.browser.js";
// import MetaMap from "./plugins/metaMap.v2.js";
import fileSaver from "https://cdn.skypack.dev/file-saver@2.0.5";
import {
  computePosition,
  shift,
  autoUpdate,
  offset,
} from "https://cdn.jsdelivr.net/npm/@floating-ui/dom@1.4.3/+esm";
document.addEventListener("DOMContentLoaded", function () {
  //accardion
  function resourcesAccardion(
    wrapperSelector,
    contentTrigger,
    contentSelector
  ) {
    const wrappers = document.querySelectorAll(wrapperSelector);

    wrappers.forEach((wrap) => {
      const trigger = wrap.querySelector(contentTrigger);
      const content = wrap.querySelector(contentSelector);
      trigger.addEventListener("click", () => {
        let maxHeight = content.querySelector("div").clientHeight + "px";
        if (trigger.classList.contains("open")) {
          trigger.classList.remove("open");
          content.classList.remove("open");
          content.style.maxHeight = 0;
        } else {
          wrappers.forEach((wrap) => {
            const trigger = wrap.querySelector(contentTrigger);
            const content = wrap.querySelector(contentSelector);

            trigger.classList.remove("open");
            content.classList.remove("open");
            content.style.maxHeight = 0;
          });
          trigger.classList.add("open");
          content.classList.add("open");
          content.style.maxHeight = maxHeight;
        }
      });
    });
  }

  resourcesAccardion(
    ".topics__accardion",
    ".topics__accardion-head",
    ".topics__accardion-content"
  );

  resourcesAccardion(
    ".first__accardion",
    ".first__accardion-head",
    ".first__accardion-content"
  );

  window.resourcesAccardion = resourcesAccardion;

  //end accardion
  function customAccardion(triggers, openOne) {
    let triggersArr = document.querySelectorAll(triggers);

    if (openOne) {
      triggersArr.forEach((trigger) => {
        trigger.addEventListener("click", () => {
          if (trigger.classList.contains("active")) {
            trigger.classList.remove("active");
          } else {
            triggersArr.forEach((trigger) => {
              trigger.classList.remove("active");
            });

            trigger.classList.add("active");
          }
        });
      });
    } else {
      triggersArr.forEach((trigger) => {
        trigger.addEventListener("click", () => {
          trigger.classList.toggle("active");
        });
      });
    }
  }

  if (document.querySelector(".mission")) {
    customAccardion(".mission__card");
  }

  if (document.querySelector(".industries")) {
    customAccardion(".industries__card", true);
  }

  if (document.querySelector(".js-dropdown")) {
    const btn = document.querySelector(".js-dropdown");
    const dropdown = document.querySelector(".header__dropdown");
    let timer;

    btn.addEventListener("click", () => {
      btn.classList.toggle("open");
      dropdown.classList.toggle("open");
    });

    btn.addEventListener("mouseenter", () => {
      clearTimeout(timer);
    });

    btn.addEventListener("mouseleave", () => {
      timer = setTimeout(() => {
        btn.classList.remove("open");
        dropdown.classList.remove("open");
      }, 1000);
    });
  }

  AOS.init({
    offset: window.innerHeight / 10,
    delay: 0,
    duration: 500,
    easing: "ease",
    once: true,
  });

  if (document.querySelector(".multi-download")) {
    const multiDownload = document.querySelector(".multi-download");

    const filesToDownload = [
      {
        url: "./images/docs/Modern_Slavery_Policy.docx",
        name: "Modern Slavery Policy",
      },
      {
        url: "./images/docs/Privacy_Policy.docx",
        name: "Privacy Policy",
      },
      {
        url: "./images/docs/Record_Keeping_Policy.docx",
        name: "Record Keeping Policy",
      },
      {
        url: "./images/docs/Recruitment_of_Ex_Offenders_Policy.docx",
        name: "Recruitment of Ex-Offenders Policy",
      },
    ];

    multiDownload.addEventListener("click", function () {
      filesToDownload.forEach((elem, index) => {
        setTimeout(() => {
          fileSaver.saveAs(elem.url, elem.name);
        }, index * 3000);
      });
    });
  }

  let userAgent = navigator.userAgent.toLowerCase();
  let osSierra = /mac os x 10/gi.test(userAgent);
  let safari10 = /version\/10.*safari/gi.test(userAgent);
  let safari11 = /version\/11.*safari/gi.test(userAgent);
  let safari12 = /version\/12.*safari/gi.test(userAgent);
  let safari13 = /version\/13.*safari/gi.test(userAgent);

  // plyr
  if (document.querySelector(".video-player")) {
    const videos = document.querySelectorAll(".video-player");

    videos.forEach((video) => {
      const player = new Plyr(video, {
        controls: [
          "play-large",
          "play",
          "progress",
          "current-time",
          "duration",
          "mute",
          "volume",
          "captions",
          "fullscreen",
        ],
      });
    });
  }
  // end plyr

  // care tabs
  const tabs = document.querySelectorAll(".care__tab");
  const content = document.querySelector(".care__col");

  tabs.forEach((tab, i) => {
    tab.addEventListener("click", () => {
      if (i === 0) {
        tabs.forEach((tab) => {
          tab.classList.remove("active");
        });
        content.classList.add("active");
        tabs[1].classList.add("active");
      }

      if (i === 1) {
        tabs.forEach((tab) => {
          tab.classList.remove("active");
        });

        content.classList.remove("active");
        tabs[0].classList.add("active");
      }
    });
  });
  // end care tabs

  // countUp
  const countsArr = [...document.getElementsByClassName(`counter__num`)];
  if (countsArr) {
    const defaultOptions = {
      separator: ",",
      enableScrollSpy: true,
      scrollSpyRunOnce: true,
    };

    countsArr.forEach((elem) => {
      let html = elem.innerHTML;
      const number = html.match("\\d+")[0];
      let prefix = "";

      if (html.indexOf(number) > 0) {
        prefix = html.slice(0, html.indexOf(number));
        html = html.replace(prefix, "");
      }

      const suffix = html.replace(number, "");

      let optionsFromDataAttr = $(elem).data();

      for (const key in optionsFromDataAttr) {
        if (optionsFromDataAttr[key] === "") {
          optionsFromDataAttr[key] = true;
        }
      }

      optionsFromDataAttr.prefix = prefix;
      optionsFromDataAttr.suffix = suffix;

      new CountUp(
        elem.id,
        number,
        Object.assign(Object.assign({}, defaultOptions), optionsFromDataAttr)
      );
    });
  }
  // end countUp

  // fix ios height
  if (innerWidth < 1024) {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
    window.addEventListener("resize", () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    });
  }
  // end fix ios height

  // burger-menu
  const burgerMenu = (menuSelector, burgerSelector) => {
    const menuElem = document.querySelector(menuSelector),
      layoutElem = document.querySelector(".header__layout"),
      burgerElem = document.querySelector(burgerSelector);

    const removeClass = () => {
      menuElem.classList.remove("active");
    };
    removeClass();

    menuElem.querySelectorAll("a").forEach((i) => {
      i.addEventListener("click", (e) => {
        if (menuElem.classList.contains("active")) {
          removeClass();
        }
      });
    });

    burgerElem.addEventListener("click", () => {
      if (menuElem.classList.contains("active")) {
        removeClass();
        scrollLock.clearQueueScrollLocks();
        scrollLock.enablePageScroll();
      } else {
        menuElem.classList.add("active");
        scrollLock.disablePageScroll();
        scrollLock.addScrollableSelector(".simplebar-content-wrapper");
      }
    });

    window.addEventListener("resize", () => {
      if (innerWidth > 1279) {
        removeClass();
      }
    });

    layoutElem.addEventListener("click", () => {
      removeClass();
    });
  };

  if (document.querySelector(".header")) {
    burgerMenu(".header", ".header__burger");
  }
  // end burger-menu

  // sticky
  let scrollPoint;

  function stickyMenu() {
    const headerElem = document.querySelector(".header");

    if (window.scrollY >= 50) {
      headerElem.classList.add("sticky");
    } else {
      headerElem.classList.remove("sticky");
    }

    if (innerWidth > 1023) {
      if (window.scrollY > scrollPoint && window.scrollY >= 50) {
        headerElem.style.transform = "translateY(-100%)";
      } else {
        headerElem.style.transform = "unset";
      }

      scrollPoint = window.scrollY;
    }
  }

  window.addEventListener("scroll", stickyMenu);
  stickyMenu();
  // end sticky

  // simplebar
  if (document.querySelector(".header__simplebar") && innerWidth < 1280) {
    new SimpleBar(document.querySelector(".header__simplebar"));
  }
  // end simplebar

  window.resetChoices = function resetChoices(choice) {
    choice.input.element.style.display = "";
    choice.removeActiveItems();
    choice.setChoiceByValue("");
  };

  window.selectArr = [];

  const locationSelect = document.querySelector("#locations");
  if (locationSelect) {
    const selectElem = new Choices("#locations", {
      allowHTML: true,
      searchEnabled: true,
    });

    const searchInput = locationSelect
      .closest(".choices")
      .querySelector('input.choices__input[type="search"]');
    searchInput.name = "";

    window.selectArr.push(selectElem);
  }
  if (document.querySelector("#salary_range")) {
    const select = new Choices("#salary_range", {
      allowHTML: true,
      searchEnabled: false,
    });

    window.selectArr.push(select);
  }

  function regionSelect(parentSelector) {
    const parentElement = document.querySelector(parentSelector);

    if (parentElement && parentElement.querySelector('#regions')) {
      const select = new Choices(`${parentSelector} #regions`, {
        allowHTML: true,
        shouldSort: false,
      });

      const input = parentElement.querySelector('#regions');
      const hiddenInputWrap = parentElement.querySelector(".input-wr--hidden")
      const hiddenInput = parentElement.querySelector(".input-wr--hidden input")

      input.addEventListener('change', () => {
        if (input.value === 'other') {
          hiddenInputWrap.classList.add('active')
        } else {
          hiddenInputWrap.classList.remove('active')
          hiddenInput.value = ''
        }
      })
      window.selectArr.push(select);
    }
  }

  const regionsSelectors = [
      '.contact-form',
      '#call_back_form',
      '#registration_form',
      '#calculator_form',
      '#upload_form',
      '#refer_form',
  ];

  regionsSelectors.forEach((selector) => {
    regionSelect(selector);
  });

  if (document.querySelector("#experience_level")) {
    const select = new Choices("#experience_level", {
      allowHTML: true,
      searchEnabled: false,
    });

    window.selectArr.push(select);
  }
  if (document.querySelector("#speciality")) {
    const select = new Choices("#speciality", {
      allowHTML: true,
      searchEnabled: false,
    });

    window.selectArr.push(select);
  }
  if (document.querySelector("#org")) {
    const select = new Choices("#org", {
      allowHTML: true,
      searchEnabled: false,
    });

    window.selectArr.push(select);
  }
  if (document.querySelector("#select")) {
    const select = new Choices("#select", {
      allowHTML: true,
      searchEnabled: false,
    });

    window.selectArr.push(select);
  }
  if (document.querySelector(".contact__select")) {
    const select = new Choices(".contact__select", {
      allowHTML: true,
      searchEnabled: false,
      shouldSort: false,
    });

    window.selectArr.push(select);
  }

  const clientsSlider = document.querySelectorAll(".clients__wrapper");

  if (clientsSlider) {
    new Swiper(`.clients__slider`, {
      loop: false,
      slidesPerView: 1,
      spaceBetween: 30,
      speed: 500,
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },
      breakpoints: {
        374: {
          spaceBetween: 40,
          slidesPerView: 2,
        },
        658: {
          spaceBetween: 40,
          slidesPerView: 4,
        },
        768: {
          spaceBetween: 50,
          slidesPerView: 5,
        },
        1024: {
          spaceBetween: 73,
          slidesPerView: 6,
        },
      },
    });
  }
  // hover awards
  const awardsBoxes = document.querySelectorAll(".awards__wrapper");

  if (awardsBoxes) {
    const awardsSwiper = new Swiper(`.awards__slider`, {
      loop: false,
      slidesPerView: 1,
      spaceBetween: 30,
      speed: 500,
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },
      breakpoints: {
        374: {
          spaceBetween: 40,
          slidesPerView: 2,
        },
        658: {
          spaceBetween: 40,
          slidesPerView: 4,
        },
        768: {
          spaceBetween: 50,
          slidesPerView: 5,
        },
        1024: {
          spaceBetween: 73,
          slidesPerView: 7,
        },
      },
    });

    awardsBoxes.forEach((awardsBox) => {
      const awards = awardsBox.querySelectorAll(".awards__logo");

      awards.forEach((item, index) => {
        const description = item.querySelector(".awards__logo-desc");

        function updatePosition() {
          computePosition(item, description, {
            placement: "top",
            middleware: [
              shift({
                padding: 25,
              }),
              offset({
                mainAxis: 20,
                crossAxis: 20,
              }),
            ],
            boundary: document.querySelector("#site"),
          }).then(({ x, y }) => {
            Object.assign(description.style, {
              left: `${x}px`,
              top: `${y}px`,
            });
          });
        }

        const cleanup = autoUpdate(item, description, updatePosition);

        item.addEventListener("mouseenter", () => {
          item.parentElement.style.zIndex = 100;
        });

        item.addEventListener("mouseleave", () => {
          item.parentElement.style.zIndex = 1;
        });
      });
    });
  }

  //  swiper
  if (document.querySelector(".industries__slider") && innerWidth < 1024) {
    MetaSwiper(`.industries__slider`, {
      loop: false,
      grabCursor: true,
      slidesPerView: 1,
      spaceBetween: 20,
      grid: {
        rows: 2,
      },
      speed: 500,
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },
      breakpoints: {
        569: {
          slidesPerView: 2,
          grid: {
            rows: 2,
          },
        },
        768: {
          slidesPerView: 3,
          grid: {
            rows: 2,
          },
        },
      },
    });
  }

  if (document.querySelector(".counter__slider")) {
    MetaSwiper(`.counter__slider`, {
      loop: false,
      slidesPerView: 1,
      spaceBetween: 40,
      speed: 500,
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },
      breakpoints: {
        100: {
          spaceBetween: 15,
          slidesPerView: 2,
        },
        658: {
          spaceBetween: 15,
          slidesPerView: 3,
        },
        768: {
          spaceBetween: 15,
          slidesPerView: 4,
        },
        1024: {
          spaceBetween: 15,
          slidesPerView: 5,
        },
      },
    });
  }

  if (document.querySelector(".news__slider")) {
    MetaSwiper(`.news__slider`, {
      loop: false,
      slidesPerView: 1,
      spaceBetween: 40,
      speed: 500,
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },
      breakpoints: {
        652: {
          spaceBetween: 15,
          slidesPerView: 2,
        },
        1024: {
          spaceBetween: 15,
          slidesPerView: 3,
        },
      },
    });
  }

  if (document.querySelector(".prioritise-list__slider")) {
    MetaSwiper(`.prioritise-list__slider`, {
      loop: false,
      slidesPerView: 2,
      spaceBetween: 40,
      speed: 500,
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },
      breakpoints: {
        568: {
          spaceBetween: 15,
          slidesPerView: 3,
        },
        768: {
          spaceBetween: 15,
          slidesPerView: 4,
        },
        1024: {
          spaceBetween: 15,
          slidesPerView: 6,
        },
      },
      navigation: {
        nextEl: ".prioritise-list__next",
        prevEl: ".prioritise-list__prev",
      },
    });
  }

  if (document.querySelector(".details__slider")) {
    MetaSwiper(`.details__slider`, {
      loop: false,
      slidesPerView: 1,
      spaceBetween: 40,
      speed: 500,
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },
      breakpoints: {
        652: {
          spaceBetween: 15,
          slidesPerView: 2,
        },
        1024: {
          spaceBetween: 15,
          slidesPerView: 3,
        },
      },
      navigation: {
        nextEl: ".details__next",
        prevEl: ".details__prev",
      },
    });
  }

  if (document.querySelector(".positions__slider")) {
    const swiper = new Swiper(`.positions__slider`, {
      loop: false,
      slidesPerView: 1,
      spaceBetween: 40,
      speed: 500,
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },
      navigation: {
        nextEl: ".positions__next",
        prevEl: ".positions__prev",
      },
      breakpoints: {
        652: {
          spaceBetween: 15,
          slidesPerView: 2,
        },
        1024: {
          spaceBetween: 15,
          slidesPerView: 3,
        },
      },
    });

    window.updatePositionSwiper = function () {
      swiper.update();
      swiper.slideTo(0);
    };
  }

  if (document.querySelector(".gallery__slider")) {
    MetaSwiper(`.gallery__slider`, {
      loop: false,
      slidesPerView: 1,
      spaceBetween: 15,
      speed: 500,
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },
      breakpoints: {
        450: {
          slidesPerView: 2,
        },
        652: {
          slidesPerView: 3,
        },
        1024: {
          slidesPerView: 4,
        },
      },
      navigation: {
        nextEl: ".gallery__next",
        prevEl: ".gallery__prev",
      },
    });
  }
  if (document.querySelector(".mission__slider")) {
    MetaSwiper(`.mission__slider`, {
      loop: false,
      slidesPerView: 1,
      spaceBetween: 15,
      speed: 500,
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },
      breakpoints: {
        450: {
          slidesPerView: 2,
        },
        652: {
          slidesPerView: 3,
        },
        1024: {
          slidesPerView: 4,
        },
      },
      navigation: {
        nextEl: ".mission__next",
        prevEl: ".mission__prev",
      },
    });
  }

  function customTabs(section, tabs, containers, sliders = false) {
    const tabTriggers = section.querySelectorAll(tabs);
    const tabContainers = section.querySelectorAll(containers);

    tabTriggers.forEach((tab, i) => {
      let newSlider;
      tab.addEventListener("click", () => {
        tabTriggers.forEach((tab, i) => {
          tab.classList.remove("active");
          tabContainers[i].classList.remove("active");
        });

        tab.classList.add("active");
        tabContainers[i].classList.add("active", "fade-in");

        if (sliders) {
          newSlider = new Swiper(sliders[i], {
            loop: false,
            slidesPerView: 1,
            spaceBetween: 15,
            speed: 500,
            autoplay: {
              delay: 4000,
              disableOnInteraction: false,
              pauseOnMouseEnter: true,
            },
            breakpoints: {
              450: {
                slidesPerView: 2,
              },
              652: {
                slidesPerView: 3,
              },
              1024: {
                slidesPerView: 4,
              },
            },
          });
        }
      });
    });
  }

  if (document.querySelector(".follow-us")) {
    const sliders = document.querySelectorAll(".follow-us__slider");
    const container = document.querySelector(".follow-us");
    customTabs(
      container,
      ".follow-us__tab",
      ".follow-us__tab-content",
      sliders
    );

    new Swiper(sliders[0], {
      loop: false,
      slidesPerView: 1,
      spaceBetween: 15,
      speed: 500,
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },
      breakpoints: {
        450: {
          slidesPerView: 2,
        },
        652: {
          slidesPerView: 3,
        },
        1024: {
          slidesPerView: 4,
        },
      },
    });
  }

  if (document.querySelector(".supply__slider") && innerWidth < 1024) {
    MetaSwiper(`.supply__slider`, {
      loop: false,
      slidesPerView: 1,
      spaceBetween: 15,
      speed: 500,
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },
      navigation: {
        nextEl: ".supply__next",
        prevEl: ".supply__prev",
      },
      breakpoints: {
        652: {
          slidesPerView: 2,
        },
        900: {
          slidesPerView: 3,
        },
      },
    });
  }
  if (document.querySelector(".headlines")) {
    const sliderThumbs = new Swiper(".headlines__thumb", {
      watchSlidesProgress: true,
      freeMode: true,
      breakpoints: {
        0: {
          direction: "horizontal",
          slidesPerView: 2,
          spaceBetween: 20,
        },
        770: {
          direction: "horizontal",
          slidesPerView: 2,
          spaceBetween: 25,
        },
        1024: {
          direction: "vertical",
          slidesPerView: 3,
          spaceBetween: 25,
        },
      },
    });
    const sliderText = new Swiper(".headlines__video", {
      navigation: {
        nextEl: ".headlines__next",
        prevEl: ".headlines__prev",
      },
      speed: 500,
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },
      grabCursor: true,
      thumbs: {
        swiper: sliderThumbs,
      },
      breakpoints: {
        0: {
          direction: "horizontal",
          slidesPerView: 1,
          spaceBetween: 40,
        },
        1024: {
          direction: "vertical",
        },
      },
    });
  }

  if (document.querySelector(".supply__slider") && innerWidth < 1024) {
    MetaSwiper(`.supply__slider`, {
      loop: false,
      slidesPerView: 1,
      spaceBetween: 15,
      speed: 500,
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },
      navigation: {
        nextEl: ".supply__next",
        prevEl: ".supply__prev",
      },
      breakpoints: {
        652: {
          slidesPerView: 2,
        },
        900: {
          slidesPerView: 3,
        },
      },
    });
  }

  if (document.querySelector(".testimonials-slider")) {
    MetaSwiper(`.testimonials-slider`, {
      loop: false,
      slidesPerView: 1,
      spaceBetween: 40,
      speed: 500,
      grabCursor: true,
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },
      navigation: {
        prevEl: ".testimonials-slider__prev",
        nextEl: ".testimonials-slider__next",
      },
    });
  }

  if (document.querySelector(".meet-team-slider")) {
    MetaSwiper(`.meet-team-slider`, {
      loop: false,
      slidesPerView: 1,
      spaceBetween: 40,
      breakpoints: {
        768: {
          spaceBetween: 15,
          slidesPerView: 2,
        },
        1150: {
          spaceBetween: 15,
          slidesPerView: 3,
        },
      },
      speed: 500,
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },
      navigation: {
        prevEl: ".meet-team__prev",
        nextEl: ".meet-team__next",
      },
    });
  }

  if (document.querySelector(".positions-slider")) {
    const swiper = new Swiper(`.positions-slider`, {
      navigation: {
        prevEl: ".vacancy-prev",
        nextEl: ".vacancy-next",
      },
      loop: false,
      slidesPerView: 1,
      spaceBetween: 40,
      speed: 500,
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },
      breakpoints: {
        568: {
          spaceBetween: 15,
          slidesPerView: 2,
        },
        1024: {
          spaceBetween: 15,
          slidesPerView: 3,
        },
      },
    });

    window.updatePositionVacancySwiper = function () {
      swiper.update();
      swiper.slideTo(0);
    };
  }

    if (document.querySelector(".ic-popup__slider")) {
      const swiper = new Swiper(`.ic-popup__slider`, {
        loop: false,
        slidesPerView: 2,
        spaceBetween: 15,
        speed: 500,
        autoplay: {
          delay: 4000,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        },
        navigation: {
          prevEl: ".ic-popup__prev",
          nextEl: ".ic-popup__next",
        },
        breakpoints: {
          658: {
            slidesPerView: 3,
          },
          1024: {
            slidesPerView: 4,
          },
        },
      });


  };
  //  end swiper

  const simpleContainer = document.querySelectorAll(".simplebar__container");

  simpleContainer.forEach((container) => {
    const scroll = container.querySelector(".simplebar-vertical");
    const content = container.querySelector(".simplebar-content");
    if (scroll.style.visibility === "visible") {
      content.classList.add("active");
    }
  });

  // topics grid
  function topicsGrid() {
    const triggers = document.querySelectorAll(".topics__head");

    triggers.forEach((trigger) => {
      const container = trigger.nextElementSibling;

      customTabs(container, ".topics__tab", ".topics__tab-content");

      trigger.addEventListener("click", (e) => {
        if (trigger.classList.contains("open")) {
          trigger.classList.remove("open");
          trigger.nextElementSibling.classList.remove("open");
        } else {
          triggers.forEach((trigger) => {
            trigger.classList.remove("open");
            trigger.nextElementSibling.classList.remove("open");
          });

          trigger.classList.add("open");
          trigger.nextElementSibling.classList.add("open");
        }
      });
    });
  }

  topicsGrid();

  function openCareerTopic() {
    const triggers = document.querySelectorAll(".topics__head");

    triggers.forEach((trigger, i) => {
      if (trigger.classList.contains("open")) {
        trigger.classList.remove("open");
        trigger.nextElementSibling.classList.remove("open");
      }

      if (i === 4) {
        trigger.classList.add("open");
        trigger.nextElementSibling.classList.add("open");
      }
    });
  }

  window.openCareerTopic = openCareerTopic;

  window.topicsGrid = topicsGrid;

  function firstGrid() {
    const triggers = document.querySelectorAll(".first__grid-head");

    triggers.forEach((trigger) => {
      trigger.addEventListener("click", (e) => {
        if (trigger.classList.contains("open")) {
          trigger.classList.remove("open");
          trigger.nextElementSibling.classList.remove("open");
        } else {
          triggers.forEach((trigger) => {
            trigger.classList.remove("open");
            trigger.nextElementSibling.classList.remove("open");
          });

          trigger.classList.add("open");
          trigger.nextElementSibling.classList.add("open");
        }
      });
    });
  }

  firstGrid();

  window.firstGrid = firstGrid
  // end topics grid

  let choiceSelectArr = document.querySelectorAll(".choice-select");
  if (choiceSelectArr) {
    choiceSelectArr.forEach((el) => {
      const select = new Choices(el, {
        allowHTML: true,
        searchEnabled: true,
      });
    });
  }

  let sgChoiceSelectArr = document.querySelectorAll(".sg-choice-select");
  if (sgChoiceSelectArr) {
    sgChoiceSelectArr.forEach((el) => {
      const select = new Choices(el, {
        allowHTML: true,
        searchEnabled: true,
      });
    });
  }
  let payRatesSwiperWr = document.querySelectorAll(".pay-rates-swiper-wr");
  payRatesSwiperWr.forEach((el) => {
    if (el) {
      let swiperEl = el.querySelector(".swiper");
      let nextEl = el.querySelector(".pay-rates-next");
      let prevEl = el.querySelector(".pay-rates-prev");
      let slidesCount = el.querySelectorAll(".swiper-slide").length;
      let metaSwiper = new Swiper(swiperEl, {
        slidesPerView: 1,
        speed: 400,
        loop: false,
        threshold: 10,
        autoHeight: true,
        effect: "fade",
        fadeEffect: {
          crossFade: true,
        },
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
          pauseOnMouseEnter: false,
        },
        navigation: {
          nextEl: nextEl,
          prevEl: prevEl,
        },
      });

      window.updatePayRatesSwiper = function () {
        metaSwiper.update();
        metaSwiper.slideTo(0);
      };
    }
  });
  /*map handler start*/
  let mapSwiperWr = document.querySelectorAll(".map-swiper-wr");
  mapSwiperWr.forEach((el) => {
    if (el) {
      let swiperEl = el.querySelector(".swiper");
      let nextEl = el.querySelector(".map-swiper-next");
      let prevEl = el.querySelector(".map-swiper-prev");
      let slidesCount = el.querySelectorAll(".swiper-slide").length;
      let swiper = new Swiper(swiperEl, {
        slidesPerView: "auto",
        spaceBetween: 15,
        speed: 500,
        threshold: 10,
        loop: false,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        },
        navigation: {
          nextEl: nextEl,
          prevEl: prevEl,
        },
        breakpoints: {
          200: {
            slidesPerView: "auto",
          },
          993: {
            slidesPerView: 2,
          },
        },
      });
      window.updateMapSwiper = function () {
        const mapSliderWrapper = document.querySelector(".map-swiper-wr");
        const map = document.getElementById("map");

        if (!mapSliderWrapper.classList.contains("active")) {
          mapSliderWrapper.classList.add("active");
        }

        map.classList.add("active");

        swiper.update();
        swiper.slideTo(0);
        swiper.autoplay.start();
      };
    }
  });
  window.initMap = function initMap(markers) {
    try {
      const map = document.getElementById("map-container");
      const mapSliderWrapper = document.querySelector(".map-swiper-wr");
      const mapHint = document.querySelector(".map-hint");
      let activeTitle = "";

      const map2 = new MetaMap("#map-container", {
        mapPath: site_dir + "public/images/map/gb.geo.json",
        zoomedCountries: "GB",
        zoomEnable: true,
        markers: markers,
        countryFillColor: "#AABCEE",
        countryStrokeWidth: "0",
        on: {
          markerMouseEnter(pin, data) {
            let mapRect = map.getBoundingClientRect();
            let pinRect = pin.getBoundingClientRect();

            const pins = document.querySelectorAll(".markers .marker");

            mapHint.innerHTML = data.title;
            mapHint.style.top = pinRect.y - mapRect.y + 5 + "px";
            mapHint.style.left = pinRect.x - mapRect.x + 10 + "px";
            mapHint.style.transform = `translate(-${
              mapHint.clientWidth / 2
            }px,-${pinRect.height}px)`;

            let hintRect = mapHint.getBoundingClientRect();

            if (hintRect.x + hintRect.width > innerWidth) {
              let diff = innerWidth - 20 - (hintRect.x + hintRect.width);
              mapHint.style.transform = `translate(-${
                mapHint.clientWidth / 2 - diff
              }px,-${pinRect.height}px)`;
            }

            mapHint.classList.add("active");
            map.classList.add("active");

            pins.forEach((el) => {
              el.classList.remove("active");
            });
            pin.classList.add("active");
          },
          markerMouseLeave(pin, data) {
            const pins = document.querySelectorAll(".markers .marker");

            mapHint.classList.remove("active");
            map.classList.remove("active");

            pins.forEach((el) => {
              el.classList.remove("active");

              if (el.classList.contains("is-slider")) {
                map.classList.add("active");

                let mapRect = map.getBoundingClientRect();
                let pinRect = el.getBoundingClientRect();

                mapHint.innerHTML = activeTitle;
                mapHint.style.top = pinRect.y - mapRect.y + 5 + "px";
                mapHint.style.left = pinRect.x - mapRect.x + 10 + "px";
                mapHint.style.transform = `translate(-${
                  mapHint.clientWidth / 2
                }px,-${pinRect.height}px)`;

                let hintRect = mapHint.getBoundingClientRect();

                if (hintRect.x + hintRect.width > innerWidth) {
                  let diff = innerWidth - 20 - (hintRect.x + hintRect.width);
                  mapHint.style.transform = `translate(-${
                    mapHint.clientWidth / 2 - diff
                  }px,-${pinRect.height}px)`;
                }

                mapHint.classList.add("active");
              }
            });
          },
          markerClick(pin, data) {
            if (!mapSliderWrapper.classList.contains("active")) {
              mapSliderWrapper.classList.add("active");
            }

            if (!pin.classList.contains("is-slider")) {
              let id = data.id;

              const pins = document.querySelectorAll(".markers .marker");
              map.classList.add("active");
              pins.forEach((el) => {
                el.classList.remove("active", "is-slider");
              });
              pin.classList.add("active", "is-slider");

              load("page/search_map", `location=${id}`);
              activeTitle = data.title;
            } else {
              return;
            }
          },
        },
        markerStyle: {
          img: site_dir + "public/images/pin.svg",
          width: 1,
          height: 2,
        },
      });
    } catch (e) {
      setTimeout(function () {
        initMap(locations);
      }, 500);
    }
  };

  if (document.querySelector("#map-container")) {
    setTimeout(function () {
      initMap(locations);
    }, 500);
  }

  /*map handler end*/

  function setAnimationMultiplyDelay(
    element,
    startDelay,
    defaultDelayStep,
    breakDelay
  ) {
    let targetArr = $(`${element}`);
    let delay = startDelay ? startDelay : 0;
    let delayStep = defaultDelayStep ? defaultDelayStep : 0;
    let counter = 0;
    if (!delayStep) return;
    targetArr.each((idx, el) => {
      if (el) {
        $(el).attr("data-aos-delay", delay);
        delay += defaultDelayStep;
        if (breakDelay) {
          counter++;
          if (counter === breakDelay) {
            delay = 0;
            counter = 0;
          }
        }
      }
    });
  }

  setAnimationMultiplyDelay(".sg-form-filters .btn", 0, 150, 4);
  setAnimationMultiplyDelay(".quiz-list-item", 0, 150, 3);
  $("body").on("click", ".ic-popup-close", function () {
    $(".ic-popup").fadeOut();
  });
  const singleRangeSlider = () => {
    const singleRange = document.getElementsByClassName(
      "single-range-default"
    )[0];
    if (singleRange) {
      const slider = singleRange.querySelector("#single-range-default");
      const max = +slider.dataset.max;
      const min = +slider.dataset.min;
      const unit = slider.dataset?.unit || "£";
      const step = +slider.dataset.step;
      const inputsHidden = singleRange.querySelector(
        ".single-range-hidden-input"
      );
      const inputInfo = singleRange.querySelector(".single-range-info");
      const startValue = +inputsHidden.value;

      noUiSlider.create(slider, {
        start: [startValue],
        connect: "lower",
        step: step,
        range: {
          min: min,
          max: max,
        },
      });

      slider.noUiSlider.on("update", function (values, handle) {
        inputInfo.textContent = `${unit + Math.round(values[handle])} miles`;

        inputsHidden.value = Math.round(values[handle]);
      });
    }
  };
  singleRangeSlider();

  let filesInput = document.querySelectorAll('[type="file"]');
  if (filesInput) {
    filesInput.forEach((file) => {
      file.addEventListener("change", function () {
        let uploadStatus =
          this.closest(".upload-btn").querySelector(".upload-status");
        uploadStatus.innerText = this.files[0].name;
      });
    });
  }
  let reviewsSection = document.querySelectorAll(".reviews");
  if (reviewsSection) {
    reviewsSection.forEach((reviews) => {
      let reviewThumb = reviews.querySelector(".reviews__thumb");
      let reviewText = reviews.querySelector(".reviews__text");
      if (reviewThumb && reviewText) {
        const sliderThumbs = new Swiper(reviewThumb, {
          direction: "horizontal",
          slidesPerView: 1,
          spaceBetween: 40,
          watchSlidesProgress: true,
          navigation: {
            nextEl: ".reviews__next",
            prevEl: ".reviews__prev",
          },
          speed: 500,
          allowTouchMove: false,
          breakpoints: {
            770: {
              direction: "horizontal",
              slidesPerView: 3,
              spaceBetween: 25,
            },
            1024: {
              direction: "vertical",
              freeMode: true,
              slidesPerView: 3,
              spaceBetween: 25,
            },
          },
        });
        const sliderText = new Swiper(reviewText, {
          direction: "horizontal",
          slidesPerView: 1,
          spaceBetween: 48,
          watchSlidesProgress: true,
          navigation: {
            nextEl: ".reviews__next",
            prevEl: ".reviews__prev",
          },
          speed: 500,
          autoplay: {
            delay: 4000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
          },
          grabCursor: true,
          thumbs: {
            swiper: sliderThumbs,
          },
          breakpoints: {
            1024: {
              direction: "vertical",
            },
          },
        });
      }
    });
  }

  const allButtons = document.querySelectorAll(".btn");
  allButtons.forEach((elem) => {
    elem.addEventListener("click", function (evt) {
      evt.preventDefault();
      const href = this.getAttribute("href");
      if (href) {
        const anchor = href.split("#")[1] ? href.split("#")[1] : "";
        const hrefWithoutAnchor = href.split("#")[0] ? href.split("#")[0] : "";

        const currentHrefRAW = window.location.href.split("#")[0];
        const currentHref = currentHrefRAW.endsWith("/")
            ? currentHrefRAW.slice(0, -1)
            : currentHrefRAW;

        if (hrefWithoutAnchor === currentHref) {
          // if same, scroll to anchor
          scrollIntoView(document.querySelector(`#${anchor}`), {
            time: 1000,
          });
        } else {
          // if not same, redirect to href
          window.location.href = href;
        }
      }
    });
  });

  if (document.querySelector(".choices")) {
    const choicesWrappers = document.querySelectorAll(".choices");
    choicesWrappers.forEach((choiceWrap) => {
      choiceWrap.addEventListener("click", () => {
        if (!choiceWrap.querySelector(".custom-simplebar-wrapper")) {
          const selectSimplebar = choiceWrap.querySelector(
              ".choices__list--dropdown .choices__list"
          );
          const div = document.createElement("div");
          div.classList.add("custom-simplebar-wrapper");
          const content = selectSimplebar.innerHTML;
          selectSimplebar.innerHTML = "";
          div.setAttribute("data-simplebar", "");
          div.innerHTML = content;
          selectSimplebar.appendChild(div);
        }
      });
    });
  }
});

// For anchor link
function scrollToCenter(selector) {
  const el = $(selector);

  $("html, body").animate(
    {
      scrollTop: el.offset().top - window.innerHeight / 2 + el.height() / 2,
    },
    400
  );
}

function scrollToAncor(selector) {
  const el = $(selector);

  $("html, body").animate(
      {
        scrollTop: el.offset().top - document.querySelector('header').offsetHeight,
      },
      400
  );
}

const scrollDownBtn = document.querySelector('.first__scroll-down')
if (scrollDownBtn) {
  scrollDownBtn.addEventListener('click', () => {
    scrollToAncor(scrollDownBtn)
  })
}

window.addEventListener("DOMContentLoaded", () => {
  if (location.hash) {
    const scrollSection = document.querySelector(location.hash);
    if (scrollSection) {
      setTimeout(function () {
        window.scrollTo(0, 0);
      }, 1);

      setTimeout(() => {
        scrollToCenter(location.hash);
      }, 500);
    }
  }
});
